<script>
import { useStore } from 'vuex'
import { onMounted } from 'vue'

import ButtonAnswersTemplate from "@/components/shared/base-components/ButtonAnswersTemplate"

const stepName = 'DoYouHaveLifeInsurance'

const ANSWERS = [
 'Yes',
 'No'
]

const questionText = 'Do you currently have life insurance?'

export default {
  name: stepName,

  emits: ['next-step'],

  components: {
    ButtonAnswersTemplate
  },

  setup(_, {emit}) {
    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const store = useStore()

    const setAnswer = (userInput) => {
      store.commit('SET_FIELD', {field: 'has_life_insurance', value: userInput.toLowerCase()})
      emit('next-step', stepName)
    }

    return {
      questionText,
      ANSWERS,
      setAnswer
    }
  }
}
</script>

<template>
  <div>
    <button-answers-template
      :button-action="setAnswer"
      :answers="ANSWERS"
      :question-text="questionText"
    />
  </div>
</template>